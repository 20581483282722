<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks">
      <template #actions v-if="$store.state.system.superAdmin">
        <lms-page-header-action-btn icon="mdi-plus" @click="createDistributor">
          create distributor
        </lms-page-header-action-btn>
      </template>
    </f-page-header>

    <lms-default-loader v-if="isLoading" />

    <f-container topBottom>
      <f-form />
    </f-container>
  </div>
</template>

<script lang="js">
import FForm from '../../../../components/views/settings/data/CountryForm.vue'


export default {
	components: {
		FForm
	},
	data: () => ({
		editing: false
	}),
	computed: {
		prevLinks() {
      return [
        {
          title: this.$t("labels.settings"),
          route: {
            name: "settings"
          }
        },
        {
          title: this.$t("labels.countries"),
          route: {
            name: "settings-country-list"
          }
        }
      ];
    },
    title() {
      return this.$t("labels.country");
    },
	},
  created(){
    this.get()
  },
  methods: {
    async get() {
      this.$store.dispatch('setting/getCountry', this.$route.params.id);
    },
    async createDistributor() {
      this.$router.push({
        name: 'settings-distributor-create',
        params: {
          countryid: this.$route.params.id
        }
      })
    }
  }
}
</script>
