<template>
  <div>
    <v-card elevation="0" v-if="loaded">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12">
            <f-lang-selector :langs="systemLangs" />
            <v-tabs-items v-model="selectedLang">
              <v-tab-item v-for="lang in systemLangs" :key="lang">
                <div class="input-spacer">
                  <v-text-field
                    v-model="country.name[lang]"
                    :rules="requiredRule"
                    disabled
                    outlined
                    :label="
                      $tt.capitalize($t('labels.name')) +
                        $tt.uppercase(` (${lang})`)
                    "
                    :counter="maxTitleSize"
                  />
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <br />

    <f-distributor-list />

    <!-- <v-btn large depressed color="primary" @click="save">
      {{ $t('labels.save') }}
    </v-btn>
    <v-btn large depressed @click="back">
      {{ $t('labels.cancel') }}
    </v-btn> -->
  </div>
</template>

<script lang="js">
import FDistributorList from './DistributorList.vue'
import validatorRules from '../../../../helpers/validator_rules'

export default {
  components: {
    FDistributorList
  },
	data: () => ({
		refresh: 0,
    tab: 0,
    maxTitleSize: 110
	}),
	computed: {
    loaded(){
      return typeof this.country.name != 'undefined'
    },
    requiredRule() {
      return validatorRules.required(this.$t("rules.required"));
    },
    systemLangs() {
      return this.$store.getters['system/langs']
    },
    selectedLang() {
      return this.$store.state.system.selectedLang;
    },
    country(){
      return this.$store.state.setting.country.view
    }
  },
  methods: {
    async save() {
			await this.$store.dispatch(
				'setting/saveCountry'
			);
			this.back()
    },
		back(){
			this.$router.push({name:'settings-country-list'});
		}
  }
}
</script>
