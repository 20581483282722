import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loaded)?_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12"}},[_c('f-lang-selector',{attrs:{"langs":_vm.systemLangs}}),_c(VTabsItems,{model:{value:(_vm.selectedLang),callback:function ($$v) {_vm.selectedLang=$$v},expression:"selectedLang"}},_vm._l((_vm.systemLangs),function(lang){return _c(VTabItem,{key:lang},[_c('div',{staticClass:"input-spacer"},[_c(VTextField,{attrs:{"rules":_vm.requiredRule,"disabled":"","outlined":"","label":_vm.$tt.capitalize(_vm.$t('labels.name')) +
                      _vm.$tt.uppercase(` (${lang})`),"counter":_vm.maxTitleSize},model:{value:(_vm.country.name[lang]),callback:function ($$v) {_vm.$set(_vm.country.name, lang, $$v)},expression:"country.name[lang]"}})],1)])}),1)],1)],1)],1)],1):_vm._e(),_c('br'),_c('f-distributor-list')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }