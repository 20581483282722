<template>
  <div>
    <lms-card>
      <lms-card-title>
        <template #icon>
          <v-icon>mdi-table-of-contents</v-icon>
        </template>

        {{ $t('labels.distributor') }}
      </lms-card-title>

      <v-data-table
        hide-default-footer
        :items-per-page="-1"
        class="elevation-0"
        :headers="headers"
        :items="items"
      >
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <lms-data-table-btn @click="edit(item._id)">
            <v-icon>mdi-pencil</v-icon>
          </lms-data-table-btn>
          <lms-data-table-btn @click="remove(item._id)">
            <v-icon>mdi-delete</v-icon>
          </lms-data-table-btn>
        </template>
      </v-data-table>
    </lms-card>
  </div>
</template>

<script lang="js">
export default {
  computed: {
    items() {
      return this.$store.state.setting.distributor.list
    },
    headers() {
      return [
        {
          text: this.$tt.capitalize(this.$t('reports.student.name')),
          align: 'start',
          value: 'title',
          sortable: false,
        },
        {
          text: this.$tt.capitalize(this.$t('labels.actions')),
          align: 'end',
          value: 'actions',
          sortable: false,
        },
      ]
    },
  },
  created() {
    this.get()
  },
  methods: {
    get() {
      const countryID = this.$route.params.id
      this.$store.dispatch('setting/listDistributors', countryID)
    },
    edit(id) {
      const countryid = this.$route.params.id
      this.$router.push({
        name: 'settings-distributor-edit',
        params: { id, countryid },
      })
    },
    async remove(id) {
      var response = await this.$store.dispatch('dialog/show', {
        title: this.$t('alerts.settings.master.removeDistributor.title'),
        content: this.$t('alerts.settings.master.removeDistributor.content'),
      })
      if (response) {
        const countryID = this.$route.params.id
        this.$store.dispatch(
          'setting/removeDistributor',
          {
            countryID,
            distributorID: id
          }
        )
        this.$store.dispatch('setting/listDistributors', countryID)
      }
    },
  },
}
</script>
